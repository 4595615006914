<template>
	<div class="roleCheck">
		<div class="titleTxt">操作日志</div>
		<div class="row-me row-center flex-wrap">
			<!-- <div class="row-me row-center">
				<div class="width220">
					<el-select placeholder="操作模块" style="width: 100%;" filterable clearable v-model="module">
						<el-option v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
			</div> -->
			<div class="row-me row-center">
				<div class="width300">
					<el-date-picker type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
						value-format="YYYY-MM-DD" style="width: 100%;" clearable v-model="selectDate">
					</el-date-picker>
				</div>
			</div>

			<el-button type="primary" class="searchs" @click="clickSearch">
				<img src="../../assets/img/icon_sousuo_b.png" alt="" />
				搜索
			</el-button>
			<el-button class="agains" @click="clickClear">重置
			</el-button>
		</div>
		<div class="column-me margin-top20">
			<div class="table-border">
				<el-table :data="tableData" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column prop="user_account" label="操作人" align="center"></el-table-column>
					<el-table-column prop="module_name" label="操作模块" align="center"></el-table-column>
					<el-table-column prop="operate_content" label="操作内容" align="center"></el-table-column>
					<el-table-column prop="create_time" label="操作时间" align="center"></el-table-column>
				</el-table>
			</div>
			<div class="row-me row-center flex-end1 margin-top10" v-if="total>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>

			<!-- <div class="row-me row-center flex-end1 margin-top10">
				<el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div> -->
		</div>

	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				module: '',
				roleArray: [{
					id: 1,
					name: '领动官'
				}, {
					id: 2,
					name: '公益天使'
				}],
				selectStatus: '',
				selectDate: [],

				tableData: [],
				pageNo: 1,
				total: 0,
			}
		},
		// 侦听器
		watch: {},
		// 计算属性
		computed: {},
		// html加载完成之前
		created() {
			this.clickSearch()
		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			clickSearch() {
				this.pageNo = 1
				this.getData()
			},
			clickClear() {
				this.module = ''
				this.selectDate = []
				this.pageNo = 1
				this.getData()
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getData()
			},
			//获取数据
			getData() {
				this.$post('/index/userOperateLog', {
					start_time: this.selectDate[0] ? this.selectDate[0] : '',
					end_time: this.selectDate[1] ? this.selectDate[1] : '',
					page: this.pageNo,
				}).then((res) => {
					if (res) {
						this.total = res.data.allListNumber
						this.tableData = res.data.listInfo
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.roleCheck {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;

		.titleTxt {
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #252630;
			padding-bottom: 30px;
		}

		.agains {
			margin-left: 10px;
			width: 100px;
			height: 40px;
			font-size: 16px;
		}

		.searchs {
			margin-left: 30px;
			font-size: 16px;

			img {
				width: 16px;
				height: 16px;
				margin-right: 10px;
			}
		}

		.title {
			font-size: 25px;
			font-weight: bold;
		}

		.left-title {
			width: 150px;
		}

		.img-head {
			width: 80px;
			height: 80px;
			border-radius: 50%;
		}

		.img-photo {
			width: 130px;
			height: 100px;
			margin-right: 20px;
			margin-bottom: 10px;
		}
	}
</style>